import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { 
  ACTIVITY_DELIVERABLE_DASHBOARD, 
  CONSULTANCY_API, CONSULTANCY_CONFIG__SUPPLIER_RELEASED, CONSULTANCY_USERS_API, CONFIGURATION_STUDY_NEW, CONFIGURATION_STUDY, 
  GET_ACTIVITIES_DELIVERABLES, GET_MY_STUDIES_CONSULTANCY,
  baseUrl} from "../../projects/constants/apiEndpointsConstants";
import { ActivityDeliverableResponse, ConsultancyConfigSupplierReleaseResponse } from "../../projects-v2/interface/activity";
import { Consultancy, ConfigurationStudy, IProfessionalResponse, NewConfigurationStudy } from "../interface/consultancy-configuration-view";
import { ConsultancyDeliverablesListRequestParams, GetConfigurationUserTableResponse } from "../../configuration/interface/configuration-user-view";

@Injectable({
  providedIn: 'root'
})
export class ConsultingConfigurationServices {
  constructor(private readonly http: HttpClient) {
  }

  getMyStudies(): Observable<any[]> {
    return this.http.get<any[]>(`${GET_MY_STUDIES_CONSULTANCY}`);
  }


  getConsultancyList(): Observable<Consultancy[]> {
    return this.http.get<Consultancy[]>(`${CONSULTANCY_API}`);
  }

  getResponsibleUserByConsultancyId(consultancyId: number): Observable<Consultancy[]> {
    return this.http.get<Consultancy[]>(`${CONSULTANCY_USERS_API}=${consultancyId}`);
  }

  getConfigSupplierReleased(activityId: number): Observable<ConsultancyConfigSupplierReleaseResponse> {
    return this.http.get<ConsultancyConfigSupplierReleaseResponse>(`${CONSULTANCY_CONFIG__SUPPLIER_RELEASED}/${activityId}`);
  }

  getActivityDeliverableDashboard(activityId: number): Observable<ActivityDeliverableResponse> {
    return this.http.get<ActivityDeliverableResponse>(`${ACTIVITY_DELIVERABLE_DASHBOARD}/${activityId}`);
  }

  postNewConfigurationStudy(params): Observable<NewConfigurationStudy> {
    return this.http.post<NewConfigurationStudy>(CONFIGURATION_STUDY_NEW, params)
  }

  getConfigurationStudy(activityId): Observable<any>{
    return this.http.get<any>(`${CONFIGURATION_STUDY}/${activityId}`);
  }

  getConfigurationConsultancies(activityId: number): Observable<any> {
    return this.http.get<any>(`${CONFIGURATION_STUDY}/${activityId}/consultancy`);
  }

  postDeliverStudyToConsultancy(studyId: number): Observable<ConfigurationStudy> {
    return this.http.post<ConfigurationStudy>(`${CONFIGURATION_STUDY_NEW}/deliver-study/${studyId}`, null);
  }

  // getActivityDeliverables(activityId, currentPage): Observable<any>{
  //   return this.http.get<any>(`${GET_ACTIVITIES_DELIVERABLES}/${activityId}`);
  // }

  getActivityDeliverables(activityId: number, filterParams?: ConsultancyDeliverablesListRequestParams, page: number = 0): Observable<GetConfigurationUserTableResponse> {
    let params: any = {};
    let sort = 'name';
    if (filterParams) {
      Object.keys(filterParams).forEach(key => {
        if (filterParams[key] !== undefined && filterParams[key] !== null && filterParams[key] !== '') {
          params[key] = filterParams[key];
        }
      });
    }

    return this.http.get<GetConfigurationUserTableResponse>(`${GET_ACTIVITIES_DELIVERABLES}/${activityId}?page=${page}&size=20&sort=${sort}`, { params });
  }

  getProfissionalsByRoleAndComplex(complexId: number, page: number = 0): Observable<IProfessionalResponse> {
    return this.http.get<IProfessionalResponse>(`${baseUrl}/users/page?roles=ROLE_LICENCIAMENTO&roles=ROLE_ESPECIALISTA&complexId=${complexId}&page=${page}&size=10`);
  }

}
